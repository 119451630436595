import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PictureType } from '../../models/picture-type.enum';

@Component({
    selector: 'gwg-identification-frontside',
    templateUrl: './identification-frontside.component.html',
    styleUrls: ['./identification-frontside.component.scss'],
    standalone: false,
})
export class IdentificationFrontsideComponent {

    type: PictureType = PictureType.Frontside;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) { }

    navigateToVerification(): void {
        this.router.navigate([{ outlets: { tab2: ['verification'] } }], { relativeTo: this.route });
    }

}

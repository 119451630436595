import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PictureType } from '../../models/picture-type.enum';

@Component({
    selector: 'gwg-verification-backside',
    templateUrl: './verification-backside.component.html',
    styleUrls: ['./verification-backside.component.scss'],
    standalone: false,
})
export class VerificationBacksideComponent {

    type: PictureType = PictureType.Backside;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly _location: Location,
    ) { }

    checkConfirmation(confirmationState: boolean): void {
        if (confirmationState) {
            this.router.navigate(['../conclusion'], { relativeTo: this.route.parent });
        } else {
            this._location.back();
        }
    }

}

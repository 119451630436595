import { ContactService } from '@abcfinlab/api/contact';
import { IHirePurchaseQuoteDto, ILeasingQuoteDto, QuoteService } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContractTypeId } from '../../../../../../apps/l7/src/app/models/enums/ContractTypeId.enum';
import { LeasingQuoteIdService } from '../services/leasing-quote-id.service';

@Injectable({
    providedIn: 'root',
})
export class QuoteSummaryResolver implements Resolve<boolean> {

    public constructor(
        private readonly _leasingQuoteIdService: LeasingQuoteIdService,
        private readonly _quoteService: QuoteService,
        private readonly _contactService: ContactService,
    ) { }

    /**
     * Decide what type of contract should be requested.
     * For some kind of reason the *MIETKAUF* request is handled differently by the BE.
     */
    public resolve(): Observable<any> {
        const contractType = this._leasingQuoteIdService.getContractType();
        const quoteId = this._leasingQuoteIdService.getLeasingQuoteId();
        let leasingQuoteSummaryRequest$: Observable<IHirePurchaseQuoteDto | ILeasingQuoteDto>;
        switch (contractType) {
            // exceptional case for *MIETKAUF* quotes
            case ContractTypeId.MIETKAUF:
            case ContractTypeId.MKN:
                leasingQuoteSummaryRequest$ = this._quoteService.getHirePurchaseQuoteById({ quoteId });
                break;
            // handling defaults to *VA*, *TA*, *IT-Flex* ... quotes
            default:
                leasingQuoteSummaryRequest$ = this._quoteService.getLeasingQuoteById({ quoteId });
        }

        return leasingQuoteSummaryRequest$.pipe(
            switchMap(quote => this._contactService.getById({ id: quote.lessee_id }).pipe(
                map(lessee => ({ ...quote, lessee })),
            )),
        );
    }

}

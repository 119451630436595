/* libs/legacy/gwg/src/lib/signing-link/signing-link.component.scss */
:host {
  text-align: center;
}
:host .headline {
  font-size: 2.5em;
  margin: 40px 0;
}
:host .copy-link-container {
  margin-top: 40px;
}
:host .copy-link-container input {
  height: 50px;
  border: 1px solid #9E9E9E;
  width: 100%;
  max-width: 650px;
  text-align: center;
  background: transparent;
  border-radius: 4px;
}
:host .copy-link-container button {
  margin-left: 8px;
}
:host p {
  max-width: 70%;
  margin: 5px auto;
}
/*# sourceMappingURL=signing-link.component.css.map */

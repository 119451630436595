<l7-page>
  <l7-page-header [title]="'Identifizierung nach dem Geldwäschegesetz'"
                  [subTitle]="'Identifizierung des Unterzeichners'"
                  [supportiveTitle]="'Bitte prüfen Sie noch einmal alle Eingaben und bestätigen Sie die Checkboxen, sofern zutreffend.'"></l7-page-header>
  <l7-page-content>
    <l7-page-section fxLayout="column">
      <gwg-signer-form [signer]="signer"
                       [disabled]="true"></gwg-signer-form>
      <hr>
      <gwg-identification-pictures [backsidePicture]="backsidePicture"
                                   [frontsidePicture]="frontsidePicture"
                                   [isFrontsidePdf]="isFrontsidePdf"
                                   [isBacksidePdf]="isBacksidePdf"></gwg-identification-pictures>
      <hr>
      <ng-container *ngIf="legalPersonality; else natural">
        <gwg-juristic-person-confirmation (formValues)="confirmationValues=$event"
                                          (valid)="disabled=!$event"></gwg-juristic-person-confirmation>
      </ng-container>
      <ng-template #natural>
        <gwg-natural-person-confirmation (valid)="disabled=!$event"></gwg-natural-person-confirmation>
      </ng-template>
      <div class="align-center">
        <l7-spacer [multiplier]="24"
                   [thickness]="'all'">
          <button mat-flat-button
                  [color]="'accent'"
                  id="button_abort-calculation"
                  [disabled]="disabled"
                  (click)="submitStandardConfirmation()">Weiter
          </button>
        </l7-spacer>
      </div>
    </l7-page-section>
  </l7-page-content>
</l7-page>
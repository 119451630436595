import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StandardConfirmationComponent } from './confirmation/standard-confirmation/standard-confirmation.component';
import { GwgComponent } from './gwg.component';
import { IdentificationBacksideComponent } from './identification/identification-backside/identification-backside.component';
import { IdentificationFrontsideComponent } from './identification/identification-frontside/identification-frontside.component';
import { VerificationBacksideComponent } from './identification/verification-backside/verification-backside.component';
import { VerificationFrontsideComponent } from './identification/verification-frontside/verification-frontside.component';
import { IdentificationContainerComponent } from './identification-container/identification-container/identification-container.component';
import { UploadFileBacksideComponent } from './identification-upload-file/upload-file-backside/upload-file-backside.component';
import { UploadFileFrontsideComponent } from './identification-upload-file/upload-file-frontside/upload-file-frontside.component';
import { GwgInitializationResolver } from './resolver/gwg-initialization.resolver';
import { IdentificationResolver } from './resolver/identification.resolver';
import { QuoteSummaryResolver } from './resolver/quote-summary.resolver';
import { FlowDecisionComponent } from './shared/flow-decision/flow-decision.component';
import { SignatureComponent } from './signature/signature/signature.component';
import { SignerDataComponent } from './signer-data/signer-data.component';
import { SignerDataConclusionComponent } from './signer-data-conclusion/signer-data-conclusion.component';
import { SigningLinkComponent } from './signing-link/signing-link.component';
import { SuccessContainerComponent } from './success/success-container/success-container.component';

const routes: Routes = [
    {
        path: '',
        component: GwgComponent,
        children: [
            {
                path: '',
                redirectTo: 'signer-data',
                pathMatch: 'full',
            },
            {
                path: 'signer-data',
                component: SignerDataComponent,
                resolve: {
                    clear: GwgInitializationResolver,
                },
            },
            {
                path: 'identification',
                component: IdentificationContainerComponent,
                children: [
                    {
                        path: '',
                        component: UploadFileFrontsideComponent,
                        outlet: 'tab1',
                    },
                    {
                        path: '',
                        component: IdentificationFrontsideComponent,
                        outlet: 'tab2',
                    },
                    {
                        path: 'verification',
                        component: VerificationFrontsideComponent,
                        outlet: 'tab2',
                    },
                ],
                data: { title: 'Vorderseite' },
            },
            {
                path: 'backside',
                component: IdentificationContainerComponent,
                children: [
                    {
                        path: '',
                        component: UploadFileBacksideComponent,
                        outlet: 'tab1',
                    },
                    {
                        path: '',
                        component: IdentificationBacksideComponent,
                        outlet: 'tab2',
                    },
                    {
                        path: 'verification',
                        component: VerificationBacksideComponent,
                        outlet: 'tab2',
                    },
                ],
                data: { title: 'Rückseite' },
            },
            {
                path: 'conclusion',
                component: SignerDataConclusionComponent,
                resolve: {
                    LegalPersonality: IdentificationResolver,
                },
            },
            {
                path: 'flow-decision',
                component: FlowDecisionComponent,
            },
            {
                path: 'signing-link',
                component: SigningLinkComponent,
            },
            {
                // Show quote summary to the user and last contract checkbox agreements
                path: 'complete-verification',
                resolve: {
                    quoteSummary: QuoteSummaryResolver,
                },
                component: StandardConfirmationComponent,
            },
            {
                path: 'signature',
                component: SignatureComponent,
            },
            {
                path: 'success',
                component: SuccessContainerComponent,
            },
            {
                path: 'pdf-view',
                loadChildren: () => import('./pdf-container/pdf-container.module').then(mod => mod.PdfContainerModule),
            },
        ],
    },

];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
})
export class GwgRoutingModule { }

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, zip } from 'rxjs';
import { ConfirmationFormDto, TransparencyRegistry } from '../models/confirmation-form.dto';
import { ConfirmationType } from '../models/confirmation-type.enum';
import { PictureType } from '../models/picture-type.enum';
import { SignerDTO } from '../models/signer-dto.interface';
import { ConfirmationService } from '../services/confirmation.service';
import { PicturesService } from '../services/pictures.service';
import { SignerService } from '../services/signer.service';

@UntilDestroy()
@Component({
    selector: 'gwg-signer-data-conclusion',
    templateUrl: './signer-data-conclusion.component.html',
    styleUrls: ['./signer-data-conclusion.component.scss'],
    standalone: false,
})
export class SignerDataConclusionComponent implements OnInit {

    legalPersonality = true;

    disabled: boolean = true;

    isFrontsidePdf: boolean;

    isBacksidePdf: boolean;

    signer: SignerDTO;

    backsidePicture: string;

    frontsidePicture: string;

    public getPictures$: Observable<[string, string]>;

    public confirmationValues: ConfirmationFormDto;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly signerService: SignerService,
        private readonly confirmationService: ConfirmationService,
        private readonly _pictureService: PicturesService,
        private readonly _cdr: ChangeDetectorRef,
    ) {
        const frontSidePicture$ = this._pictureService.getIdentificationDocument(PictureType.Frontside);
        const backsSidePicture$ = this._pictureService.getIdentificationDocument(PictureType.Backside);
        this.getPictures$ = zip(frontSidePicture$, backsSidePicture$);
    }

    ngOnInit() {
        this.getPictures$
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                this.frontsidePicture = result[0];
                this.backsidePicture = result[1];
                this.isFrontsidePdf = this.frontsidePicture.includes('data:application/pdf');

                this.isBacksidePdf = this.backsidePicture.includes('data:application/pdf');

                if (!this.frontsidePicture.includes('data:image') && !this.isFrontsidePdf) {
                    this.frontsidePicture = `data:image/png;base64,${this.frontsidePicture}`;
                }

                if (!this.backsidePicture.includes('data:image') && !this.isBacksidePdf) {
                    this.backsidePicture = `data:image/png;base64,${this.backsidePicture}`;
                }
                this._cdr.detectChanges();
            });

        this.legalPersonality = this.route.snapshot.data.LegalPersonality;

        this.signerService.signer$
            .pipe(untilDestroyed(this))
            .subscribe((signer) => {
                this.signer = signer;
            });
    }

    submitStandardConfirmation(): void {
        if (this.legalPersonality) {
            const transparencyRegistryType = this.confirmationValues.transparencyRegistryNotification === TransparencyRegistry.MISSING
                ? ConfirmationType.TRANSPARENCY_REGISTER_NOTIFICATION_MISSING
                : ConfirmationType.TRANSPARENCY_REGISTER_NOTIFICATION_ENTRY;
            this.confirmationService.postJuristicPersonConfirmation(transparencyRegistryType)
                .pipe(untilDestroyed(this))
                .subscribe(() => this.navigateToCompleteVerification());
        } else if (!this.legalPersonality) {
            this.confirmationService.postNaturalPersonConfirmation()
                .pipe(untilDestroyed(this))
                .subscribe(() => this.navigateToCompleteVerification());
        }
    }

    navigateToCompleteVerification(): void {
        this.router.navigate(['../flow-decision'], { relativeTo: this.route });
    }

}

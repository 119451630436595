import { Location } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PictureType } from '../../models/picture-type.enum';

@Component({
    selector: 'gwg-verification-frontside',
    templateUrl: './verification-frontside.component.html',
    styleUrls: ['./verification-frontside.component.scss'],
    standalone: false,
})
export class VerificationFrontsideComponent {

    type: PictureType = PictureType.Frontside;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly _location: Location,
        private readonly _cdr: ChangeDetectorRef,
    ) { }

    checkConfirmation(confirmationState: boolean): void {
        if (confirmationState) {
            this.router.navigate(['../backside'], { relativeTo: this.route.parent });
        } else {
            this._location.back();
        }
    }

}

import { ILinkDto, SigningLinkQuoteService } from '@abcfinlab/api/global';
import { ToastService } from '@abcfinlab/ui';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LeasingQuoteIdService } from '../services/leasing-quote-id.service';
import { PicturesService } from '../services/pictures.service';

@UntilDestroy()
@Component({
    selector: 'gwg-signing-link',
    templateUrl: './signing-link.component.html',
    styleUrls: ['./signing-link.component.scss'],
    standalone: false,
})
export class SigningLinkComponent implements OnInit {

    public showButton: boolean;

    public signingLinkObject: ILinkDto;

    constructor(
        private readonly _router: Router,
        private readonly _toast: ToastService,
        private readonly _pictureService: PicturesService,
        private readonly _signingLinkService: SigningLinkQuoteService,
        private readonly _leasingQuoteIDService: LeasingQuoteIdService,
        private readonly _dialog: MatDialog,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this._signingLinkService.createSigningLink({ leasingQuoteId: this._leasingQuoteIDService.getLeasingQuoteId() })
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
                this.signingLinkObject = res;
                this._dialog.closeAll();
                this.showButton = true;
                this._cdr.detectChanges();
            }, (error) => {
                this._dialog.closeAll();
                this.showButton = true;
                alert(error);
            });
    }

    get_signing_link(): string {
        return this.signingLinkObject.link;
    }

    get_expires_at(): string {
        // @todo: Weird dateformat ... ... ...
        return this.signingLinkObject.expires_at.replace(/\[(.*?)]/g, '');
    }

    public onBackToStart(): void {
        this._pictureService.clearDocumentStorage()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                void this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
            });
    }

    public onLinkCopy(payload: boolean): void {
        this._toast.showSuccess('In Zwischenablage gespeichert!');
    }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoneyLaunderingIdentificationDTO } from '../models/money-laundering-identification-dto';
import { MoneyLaunderingIdentificationService } from '../services/money-laundering-identification.service';

@Injectable({
    providedIn: 'root',
})
export class IdentificationResolver implements Resolve<boolean> {

    constructor(
        private readonly moneyLaunderingIdentificationService: MoneyLaunderingIdentificationService,
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.moneyLaunderingIdentificationService.getMoneyLaunderingIdentification()
            .pipe(
                map(moneyLaunderingIdentificationDTO => this.resolveLegalPersonality(moneyLaunderingIdentificationDTO)),
            );
    }

    private resolveLegalPersonality(moneyLaunderingIdentificationDTO: MoneyLaunderingIdentificationDTO): boolean {
        return moneyLaunderingIdentificationDTO.legal_personality === 'J';
    }

}

/* libs/legacy/gwg/src/lib/signer-data-conclusion/signer-data-conclusion.component.scss */
h2 {
  text-transform: uppercase;
}
h2,
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
}
h1,
h3 {
  margin-bottom: 30px;
}
.align-center {
  display: flex;
  justify-content: center;
}
hr {
  border-top: 1px solid #eee;
}
/*# sourceMappingURL=signer-data-conclusion.component.css.map */

export enum TransparencyRegistry {
    MISSING = 'missing',
    ENTRY = 'entry',
}

export interface ConfirmationFormDto {
    signerJuristicPerson: boolean;
    registerInformationConfirmation: boolean;
    transparencyRegistryNotification: TransparencyRegistry;
}

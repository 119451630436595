/* libs/legacy/gwg/src/lib/identification-container/identification-container/identification-container.component.scss */
:host .mat-mdc-tab-group .activeTab {
  color: #1b7e9a;
}
h1,
h2 {
  text-transform: uppercase;
}
h2,
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
}
h1,
h3 {
  margin-bottom: 30px;
}
mat-icon {
  font-size: 16px !important;
}
/*# sourceMappingURL=identification-container.component.css.map */

import { TranslationModule } from '@abcfinlab/core';
import { PageModule } from '@abcfinlab/layout';
import { SpacerModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SharedModule } from '../../../../../apps/l7/src/app/shared/shared.module';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { GwgRoutingModule } from './gwg-routing.module';
import { GwgComponent } from './gwg.component';
import { IdentificationContainerModule } from './identification-container/identification-container.module';
import { SharedModule as SharedModuleLb } from './shared/shared.module';
import { SignatureModule } from './signature/signature.module';
import { SignerDataComponent } from './signer-data/signer-data.component';
import { SignerDataConclusionComponent } from './signer-data-conclusion/signer-data-conclusion.component';
import { SigningLinkComponent } from './signing-link/signing-link.component';
import { SuccessModule } from './success/success.module';

@NgModule({
    declarations: [
        GwgComponent,
        SignerDataComponent,
        SignerDataConclusionComponent,
        SigningLinkComponent,
    ],
    imports: [
        SharedModuleLb,
        FormsModule,
        RouterModule,
        CommonModule,
        IdentificationContainerModule,
        ConfirmationModule,
        GwgRoutingModule,
        SharedModule,
        SignatureModule,
        SuccessModule,
        FlexLayoutModule,
        MatButtonModule,
        PageModule,
        SpacerModule,
        MatButtonModule,
        TranslationModule,
    ],
    exports: [
        GwgComponent,
        SignerDataComponent,
    ],
})
export class GwgModule { }

<div class="page-container">
  <ng-container *ngIf="signingLinkObject">
    <div class="image-container">
      <img src="assets/images/gwg-images/success.png"
           alt="Vertrag fertig zur Unterschrift!">
    </div>
    <h2 class="headline text-bold">Vertrag fertig zur Unterschrift!</h2>
    <p class="text-medium">
      Der Vertrag kann unter nachfolgendem Link aufgerufen werden.
      <br>
      Eine Kopie des Links wurde an ihr Postfach gesendet.
    </p>
    <div class="copy-link-container">
      <input id="signing-link"
             type="text"
             [value]="get_signing_link()"
             [disabled]="true">
      <button mat-button
              [color]="'accent'"
              [cdkCopyToClipboard]="get_signing_link()"
              (cdkCopyToClipboardCopied)="onLinkCopy($event)">Link kopieren</button>
    </div>
    <p>
      Der Link ist gültig bis <span class="text-bold">{{get_expires_at() | date : 'EEEE, dd MMMM HH:mm'}} Uhr (MEZ)</span>.
    </p>
  </ng-container>
  <div class="button-row"
       *ngIf="showButton">
    <button mat-flat-button
            [color]="'accent'"
            id="button_back-to-start"
            (click)="onBackToStart()">Zur Startseite</button>
  </div>
</div>
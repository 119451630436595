import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { IdentificationBacksideComponent } from './identification-backside/identification-backside.component';
import { IdentificationFrontsideComponent } from './identification-frontside/identification-frontside.component';
import { IdentificationRoutingModule } from './identification-routing.module';
import { VerificationBacksideComponent } from './verification-backside/verification-backside.component';
import { VerificationFrontsideComponent } from './verification-frontside/verification-frontside.component';

@NgModule({
    declarations: [
        IdentificationBacksideComponent,
        IdentificationFrontsideComponent,
        VerificationBacksideComponent,
        VerificationFrontsideComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        IdentificationRoutingModule,
    ],
    exports: [
        IdentificationBacksideComponent,
        IdentificationFrontsideComponent,
        VerificationBacksideComponent,
        VerificationFrontsideComponent,
    ],
})
export class IdentificationModule { }
